import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/website/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "setup-development-tools"
    }}>{`Setup Development Tools`}</h1>
    <p>{`In this article we are preparing for the `}<a parentName="p" {...{
        "href": "./DevelopmentProcess-Tasks.md"
      }}>{`development process for tasks`}</a>{` document by installing some extra tools.`}</p>
    <p><a parentName="p" {...{
        "href": "./RunningLocally.md"
      }}>{`Running locally`}</a>{` goes through installing all the tools it takes to run our application locally.`}</p>
    <p><a parentName="p" {...{
        "href": "./SetupDatabaseConnectionInSequelPro.md"
      }}>{`Setup database connection in Sequel Pro`}</a>{`.`}</p>
    <p><a parentName="p" {...{
        "href": "./SetupVSCode.md"
      }}>{`Setup VSCode (Visual Studio Code)`}</a>{`.`}</p>
    <h2 {...{
      "id": "setup-git-town"
    }}>{`Setup Git-Town`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Install git-town.`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`brew install git-town
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Open terminal using mac's spotlight (Command+Space).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`cd into your glue-stack directory.`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`cd development/glue-stack
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Run the git-town setup.`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`git-town config setup
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select master as our main branch`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Don't select any perennial branches.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "setup-oh-my-zsh"
    }}>{`Setup oh-my-zsh`}</h2>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://github.com/robbyrussell/oh-my-zsh#basic-installation"
        }}>{`Basic Installation`}</a></li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      